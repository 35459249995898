import { Link } from "gatsby";
import React from "react";
import ReactMarkdown from "react-markdown";
import {
  OnlineAndMaterialBuySection,
  OnlineOnlyBuySection,
} from "../BuySections";

export function Section({
  blue,
  item,
  asMarkdown,
  transformImageUri,
  titleLink,
}) {
  const isRight = item.orientation === "right";
  return (
    <div className={blue ? "relative bg-blue-100" : "relative"}>
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 align-middle items-center">
        <div
          className={`px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0  ${
            blue ? "lg:py-16" : "lg:py-8"
          } ${isRight ? "lg:col-start-2" : ""}`}
        >
          <div>
            <div className="mt-6">
              <h2 className="text-3xl tracking-tight text-blue-700">
                {titleLink ? <a href={item.url}>{item.title}</a> : item.title}
              </h2>
              {asMarkdown ? (
                <div className="prose prose-sm sm:prose lg:prose-lg mx-auto">
                  <ReactMarkdown
                    transformImageUri={transformImageUri}
                    allowDangerousHtml
                    components={{
                      blockquote: ({ node, ...props }) => {
                        const materialLink = props.children.some((it) =>
                          it.props?.children?.includes("materialLink"),
                        );
                        const onlineLink = props.children.some((it) =>
                          it.props?.children?.includes("onlineLink"),
                        );
                        if (materialLink)
                          return <OnlineAndMaterialBuySection />;
                        if (onlineLink) return <OnlineOnlyBuySection />;
                        return <em {...props} />;
                      },
                    }}
                    children={item.text}
                  />
                </div>
              ) : (
                <p className="mt-4 text-lg text-gray-700 whitespace-pre-wrap">
                  {item.text}
                </p>
              )}
            </div>
          </div>
          {item.link && (
            <div className="mt-10 lg:flex-shrink-0 mx-12 md:mx-0">
              <div className="rounded-md text-center md:text-left">
                <Link
                  to={item.link}
                  className="inline-flex bg-blue-700 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:bg-blue-600"
                >
                  {item.linktext}
                </Link>
              </div>
            </div>
          )}
        </div>
        {item.imageComponent && item.imageComponent}
        {item.image ? (
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div
              className={`lg:px-0 lg:mt-8 lg:relative lg:h-full ${
                isRight ? "lg:col-start-1" : ""
              }`}
            >
              <img
                className="mt-10 relative lg:mt-0 lg:col-start-1 w-full shadow-xl ring-1 ring-black ring-opacity-5 md:rounded-xl"
                src={item.image}
                alt={item.imagealt}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
